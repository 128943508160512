import React, { useEffect, useState, useRef } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductsAsync,
  productsResponse,
  addProductAsync,
  productAddResponse,
  updateProductAsync,
  productUpdateResponse,
  deleteProductAsync,
  productDeleteResponse,
  getShopCategoriesAsync,
  shopCategoriesResponse,
  resetAsync,
} from "../../api/slices/shop.slice";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../api/client/api.constants";
import JoditEditor from "jodit-react";

function Products(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();

  const _productsResponse = useSelector(productsResponse);
  const _productUpdateResponse = useSelector(productUpdateResponse);
  const _productDeleteResponse = useSelector(productDeleteResponse);
  const _productAddResponse = useSelector(productAddResponse);
  const _shopCategoriesResponse = useSelector(shopCategoriesResponse);

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [product, setProduct] = useState("");
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState([]);
  const [categories, setCategories] = useState([]);

  const [showAddModel, setShowAddModel] = useState(false);
  const handleAddModelClose = () => {
    setShowAddModel(false);
  };
  const handleAddModelShow = () => {
    setProduct({});
    setShowAddModel(true);
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const handleEditModelClose = () => {
    setShowEditModel(false);
  };
  const handleEditModelShow = (index) => {
    setIndex(index);
    setProduct(products[index]);
    setShowEditModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const hiddenMediaInput = useRef(null);
  const hiddenPDFInput = useRef(null);
  const editor = useRef(null);
  const config = {
    readonly: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
  };

  const columns = [
    { key: "id", value: "ID" },
    { key: "name", value: "Name" },
    { key: "category", value: "Category" },
    { key: "price", value: "Price" },
    { key: "mmk_price", value: "MMK Price" },
    { key: "media", value: "Media" },
    { key: "created_at", value: "Created At" },
    { key: "active", value: "Active" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(getProductsAsync({ page: page.page, page_size: page.page_size }));
    dispatch(getShopCategoriesAsync({ page: 1, page_size: 100 }));
  }, [page]);

  useEffect(() => {
    setLoading(_productsResponse.isLoading);
    if (
      _productsResponse.response &&
      _productsResponse.response.status === 200
    ) {
      window.scrollTo(0, 0);
      setProducts(_productsResponse.response.data.products);
      setPaging(_productsResponse.response.data.paging);
    } else {
      toast.error(_productsResponse.response?.message);
    }
  }, [_productsResponse]);

  useEffect(() => {
    setLoading(_productUpdateResponse.isLoading);
    if (
      _productUpdateResponse.response &&
      _productUpdateResponse.response.status === 200
    ) {
      setProduct({});
      dispatch(
        getProductsAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_productUpdateResponse.response?.message);
    }
  }, [_productUpdateResponse]);

  useEffect(() => {
    setLoading(_productAddResponse.isLoading);
    if (
      _productAddResponse.response &&
      _productAddResponse.response.status === 200
    ) {
      setProduct({});
      dispatch(
        getProductsAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_productAddResponse.response?.message);
    }
  }, [_productAddResponse]);

  useEffect(() => {
    setLoading(_productDeleteResponse.isLoading);
    if (
      _productDeleteResponse.response &&
      _productDeleteResponse.response.status === 200
    ) {
      setProduct({});
      dispatch(
        getProductsAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_productDeleteResponse.response?.message);
    }
  }, [_productDeleteResponse]);

  useEffect(() => {
    setLoading(_shopCategoriesResponse.isLoading);
    if (
      _shopCategoriesResponse.response &&
      _shopCategoriesResponse.response.status === 200
    ) {
      setCategories(_shopCategoriesResponse.response.data.categories);
    } else {
      toast.error(_shopCategoriesResponse.response?.message);
    }
  }, [_shopCategoriesResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "updated_at"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getProductsAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getProductsAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getProductsAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: products[index].id,
    };
    dispatch(deleteProductAsync(data));
  };

  const onAddProduct = () => {
    setShowAddModel(false);
    dispatch(addProductAsync(product));
  };

  const onEditProduct = () => {
    setShowEditModel(false);
    if (typeof product.media == "string") {
      delete product.media;
    }
    if (typeof product.link == "string") {
      delete product.link;
    }
    product.category_id = parseInt(product.category_id);
    delete product.created_at;
    delete product.category;
    dispatch(updateProductAsync(product));
  };

  const handleMediaClick = (event) => {
    hiddenMediaInput.current.click();
  };

  const handlePDFClick = (event) => {
    hiddenPDFInput.current.click();
  };

  const handleMediaChange = (event) => {
    const fileUploaded = event.target.files[0];
    setProduct({ ...product, media: fileUploaded });
  };

  const handlePDFChange = (event) => {
    const fileUploaded = event.target.files[0];
    setProduct({ ...product, link: fileUploaded });
  };

  const handleActiveChange = (index) => {
    setIndex(index);
    dispatch(
      updateProductAsync({
        id: products[index].id,
        active: !products[index].active,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Products
              <p className="text-muted">All Products</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add Products
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((item, i) => {
                      return (
                        <tr className="text-center">
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.category.name}</td>
                          <td>{item.price}</td>
                          <td>{item.mmk_price}</td>
                          <td>
                            <Image
                              fluid
                              width={50}
                              height={50}
                              alt="#"
                              src={`${API_ENDPOINTS.IMAGE}${item.media}`}
                            />
                          </td>
                          <td>{item.created_at}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditModelShow(i)}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteModelShow(i)}
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal size="lg" show={showEditModel} onHide={handleEditModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={product.name}
                  onChange={(e) => {
                    setProduct({ ...product, name: e.target.value });
                  }}
                  type="text"
                  placeholder="Name"
                />

                <div style={{ marginTop: "15px" }}>
                  <JoditEditor
                    ref={editor}
                    value={product.description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setProduct({ ...product, description: newContent })
                    }
                  />
                </div>

                <Form.Control
                  style={{ marginTop: "15px" }}
                  value={product.price}
                  onChange={(e) => {
                    setProduct({ ...product, price: e.target.value });
                  }}
                  type="number"
                  placeholder="Price"
                />

                <Form.Control
                  style={{ marginTop: "15px" }}
                  value={product.mmk_price}
                  onChange={(e) => {
                    setProduct({ ...product, mmk_price: e.target.value });
                  }}
                  type="number"
                  placeholder="MMK Price"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={product.category_id}
                  onChange={(e) => {
                    setProduct({ ...product, category_id: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Category</option>
                  {categories.map((item) => {
                    return <option value={item.id}> {item.name}</option>;
                  })}
                </Form.Control>

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="Product Image"
                    value={
                      typeof product.media == "string"
                        ? product.media
                        : product.media?.name
                    }
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={handleMediaClick}
                      variant="outline-secondary"
                    >
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleMediaChange}
                  ref={hiddenMediaInput}
                  style={{ display: "none" }}
                />

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="PDF"
                    value={
                      typeof product.link == "string"
                        ? product.link
                        : product.link?.name
                    }
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={handlePDFClick}
                      variant="outline-secondary"
                    >
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <input
                  type="file"
                  accept="application/pdf, application/vnd.ms-excel"
                  onChange={handlePDFChange}
                  ref={hiddenPDFInput}
                  style={{ display: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!product.name || product.name == "") {
                toast.error("Please enter name");
              } else if (!product.description || product.description == "") {
                toast.error("Please enter product description");
              } else if (!product.price || product.price == "") {
                toast.error("Please enter product price");
              } else if (!product.mmk_price || product.mmk_price == "") {
                toast.error("Please enter product MMK price");
              } else if (!product.category_id || product.category_id == "") {
                toast.error("Please select product category");
              } else if (!product.media) {
                toast.error("Please select product media");
              } else if (!product.link) {
                toast.error("Please select product PDF");
              } else {
                onEditProduct();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={product.name}
                  onChange={(e) => {
                    setProduct({ ...product, name: e.target.value });
                  }}
                  type="text"
                  placeholder="Name"
                />

                <div style={{ marginTop: "15px" }}>
                  <JoditEditor
                    ref={editor}
                    value={product.description}
                    config={config}
                    tabIndex={1}
                    onBlur={(newContent) =>
                      setProduct({ ...product, description: newContent })
                    }
                  />
                </div>

                <Form.Control
                  style={{ marginTop: "15px" }}
                  value={product.price}
                  onChange={(e) => {
                    setProduct({ ...product, price: e.target.value });
                  }}
                  type="number"
                  placeholder="Price"
                />

                <Form.Control
                  style={{ marginTop: "15px" }}
                  value={product.mmk_price}
                  onChange={(e) => {
                    setProduct({ ...product, mmk_price: e.target.value });
                  }}
                  type="number"
                  placeholder="MMK Price"
                />

                <Form.Control
                  as="select"
                  className="mt-3"
                  value={product.category_id}
                  onChange={(e) => {
                    setProduct({ ...product, category_id: e.target.value });
                  }}
                >
                  <option value={"PLACEHOLDER"}>Select Category</option>
                  {categories.map((item) => {
                    return <option value={item.id}> {item.name}</option>;
                  })}
                </Form.Control>

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="Product Image"
                    value={product.media?.name}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={handleMediaClick}
                      variant="outline-secondary"
                    >
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleMediaChange}
                  ref={hiddenMediaInput}
                  style={{ display: "none" }}
                />

                <InputGroup style={{ marginTop: "15px" }} className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="PDF"
                    value={product.link?.name}
                  />
                  <InputGroup.Append>
                    <Button
                      onClick={handlePDFClick}
                      variant="outline-secondary"
                    >
                      Choose
                    </Button>
                  </InputGroup.Append>
                </InputGroup>

                <input
                  type="file"
                  accept="application/pdf, application/vnd.ms-excel"
                  onChange={handlePDFChange}
                  ref={hiddenPDFInput}
                  style={{ display: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!product.name || product.name == "") {
                toast.error("Please enter name");
              } else if (!product.description || product.description == "") {
                toast.error("Please enter product description");
              } else if (!product.price || product.price == "") {
                toast.error("Please enter product price");
              } else if (!product.mmk_price || product.mmk_price == "") {
                toast.error("Please enter product MMK price");
              } else if (!product.category_id || product.category_id == "") {
                toast.error("Please select product category");
              } else if (!product.media) {
                toast.error("Please select product media");
              } else if (!product.link) {
                toast.error("Please select product PDF");
              } else {
                onAddProduct();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(Products);
