import { createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../client/api.constants";

import { postAPICall, getAPICall, deleteAPICall } from "../client/api.client";

const initialState = {
  shop_categories: {
    isLoading: false,
  },
  shop_category_update: {
    isLoading: false,
  },
  shop_category_delete: {
    isLoading: false,
  },
  shop_category_add: {
    isLoading: false,
  },

  products: {
    isLoading: false,
  },
  product_update: {
    isLoading: false,
  },
  product_delete: {
    isLoading: false,
  },
  product_add: {
    isLoading: false,
  },

  requests: {
    isLoading: false,
  },
  update_requests: {
    isLoading: false,
  },
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    shop_categories: (state, action) => {
      state.shop_categories = action.payload;
    },
    shop_category_update: (state, action) => {
      state.shop_category_update = action.payload;
    },
    shop_category_add: (state, action) => {
      state.shop_category_add = action.payload;
    },
    shop_category_delete: (state, action) => {
      state.shop_category_delete = action.payload;
    },

    products: (state, action) => {
      state.products = action.payload;
    },
    product_update: (state, action) => {
      state.product_update = action.payload;
    },
    product_delete: (state, action) => {
      state.product_delete = action.payload;
    },
    product_add: (state, action) => {
      state.product_add = action.payload;
    },

    requests: (state, action) => {
      state.requests = action.payload;
    },
    update_requests: (state, action) => {
      state.update_requests = action.payload;
    },

    reset: (state, action) => {
      state.shop_categories = {
        isLoading: false,
      };
      state.shop_category_update = {
        isLoading: false,
      };
      state.shop_category_add = {
        isLoading: false,
      };
      state.shop_category_delete = {
        isLoading: false,
      };

      state.products = {
        isLoading: false,
      };
      state.product_update = {
        isLoading: false,
      };
      state.product_delete = {
        isLoading: false,
      };
      state.product_add = {
        isLoading: false,
      };
    },
  },
});

export const getShopCategoriesAsync = (params) => async (dispatch) => {
  try {
    dispatch(shop_categories({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETSHOPCATEGORIES, params);
    dispatch(shop_categories({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(shop_categories({ isLoading: false }));
  }
};

export const updateShopCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(shop_category_update({ isLoading: true }));
    const result = await postAPICall(
      API_ENDPOINTS.UPDATESHOPCATEGORIES,
      params
    );
    dispatch(shop_category_update({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(shop_category_update({ isLoading: false }));
  }
};

export const addShopCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(shop_category_add({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDSHOPCATEGORIES, params);
    dispatch(shop_category_add({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(shop_category_add({ isLoading: false }));
  }
};

export const deleteShopCategoryAsync = (params) => async (dispatch) => {
  try {
    dispatch(shop_category_delete({ isLoading: true }));
    const result = await deleteAPICall(
      API_ENDPOINTS.DELETESHOPCATEGORIES,
      params
    );
    dispatch(shop_category_delete({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(shop_category_delete({ isLoading: false }));
  }
};

export const getRequestsAsync = (params) => async (dispatch) => {
  try {
    dispatch(requests({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.PRODUCTREQUESTS, params);
    dispatch(requests({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(requests({ isLoading: false }));
  }
};
export const updateRequestAsync = (params) => async (dispatch) => {
  try {
    dispatch(update_requests({ isLoading: true }));
    const result = await postAPICall(
      API_ENDPOINTS.UPDATEPRODUCTPRODUCT,
      params
    );
    dispatch(update_requests({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(update_requests({ isLoading: false }));
  }
};

export const getProductsAsync = (params) => async (dispatch) => {
  try {
    dispatch(products({ isLoading: true }));
    const result = await getAPICall(API_ENDPOINTS.GETPRODUCTS, params);
    dispatch(products({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(products({ isLoading: false }));
  }
};

export const updateProductAsync = (params) => async (dispatch) => {
  try {
    dispatch(product_update({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.UPDATEPRODUCT, params, true);
    dispatch(product_update({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(product_update({ isLoading: false }));
  }
};

export const addProductAsync = (params) => async (dispatch) => {
  try {
    dispatch(product_add({ isLoading: true }));
    const result = await postAPICall(API_ENDPOINTS.ADDPRODUCT, params, true);
    dispatch(product_add({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(product_add({ isLoading: false }));
  }
};

export const deleteProductAsync = (params) => async (dispatch) => {
  try {
    dispatch(product_delete({ isLoading: true }));
    const result = await deleteAPICall(API_ENDPOINTS.DELETEPRODUCT, params);
    dispatch(product_delete({ isLoading: false, response: result.data }));
  } catch (error) {
    dispatch(product_delete({ isLoading: false }));
  }
};

export const {
  shop_categories,
  shop_category_update,
  shop_category_delete,
  shop_category_add,

  products,
  product_update,
  product_delete,
  product_add,

  requests,
  update_requests,
} = shopSlice.actions;

export const shopCategoriesResponse = (state) => state.shop.shop_categories;
export const shopUpdateCategoryResponse = (state) =>
  state.shop.shop_category_update;
export const shopDeleteCategoryResponse = (state) =>
  state.shop.shop_category_delete;
export const shopAddCategoryResponse = (state) => state.shop.shop_category_add;

export const productsResponse = (state) => state.shop.products;
export const productUpdateResponse = (state) => state.shop.product_update;
export const productDeleteResponse = (state) => state.shop.product_delete;
export const productAddResponse = (state) => state.shop.product_add;
export const requestsResponse = (state) => state.shop.requests;
export const updateRequestResponse = (state) => state.shop.update_requests;

export default shopSlice.reducer;
