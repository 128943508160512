// store.ts
import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./api/slices/auth.slice";
import usersSlice from "./api/slices/users.slice";
import subscriptionSlice from "./api/slices/subscription.slice";
import cmsSlice from "./api/slices/cms.slice";
import sessionsSlice from "./api/slices/sessions.slice";
import questionsSlice from "./api/slices/questions.slice";
import shopSlice from "./api/slices/shop.slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    users: usersSlice,
    subscription: subscriptionSlice,
    cms: cmsSlice,
    sessions: sessionsSlice,
    questions: questionsSlice,
    shop: shopSlice,
  },
});
