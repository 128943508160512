import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getShopCategoriesAsync,
  shopCategoriesResponse,
  addShopCategoryAsync,
  shopAddCategoryResponse,
  updateShopCategoryAsync,
  shopUpdateCategoryResponse,
  deleteShopCategoryAsync,
  shopDeleteCategoryResponse,
  resetAsync,
} from "../../api/slices/shop.slice";
import { toast } from "react-toastify";

function ShopCategories(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();

  const _categoriesResponse = useSelector(shopCategoriesResponse);
  const _categoryUpdateResponse = useSelector(shopUpdateCategoryResponse);
  const _categoryDeleteResponse = useSelector(shopDeleteCategoryResponse);
  const _categoryAddResponse = useSelector(shopAddCategoryResponse);

  const [index, setIndex] = useState();
  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [category, setCategory] = useState("");
  const [order_by, setOrderBy] = useState("DESC");
  const [query, setQuery] = useState();
  const [categories, setCategories] = useState([]);
  const [paging, setPaging] = useState([]);

  const [showAddModel, setShowAddModel] = useState(false);
  const handleAddModelClose = () => {
    setShowAddModel(false);
  };
  const handleAddModelShow = () => {
    setCategory({});
    setShowAddModel(true);
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const handleEditModelClose = () => {
    setShowEditModel(false);
  };
  const handleEditModelShow = (index) => {
    setIndex(index);
    setCategory(categories[index]);
    setShowEditModel(true);
  };

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };
  const handleDeleteModelShow = (index) => {
    setIndex(index);
    setShowDeleteModel(true);
  };

  const columns = [
    { key: "id", value: "ID" },
    { key: "name", value: "Name" },
    { key: "created_at", value: "Created At" },
    { key: "active", value: "Active" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(
      getShopCategoriesAsync({ page: page.page, page_size: page.page_size })
    );
  }, [page]);

  useEffect(() => {
    setLoading(_categoriesResponse.isLoading);
    if (
      _categoriesResponse.response &&
      _categoriesResponse.response.status === 200
    ) {
      window.scrollTo(0, 0);
      setCategories(_categoriesResponse.response.data.categories);
      setPaging(_categoriesResponse.response.data.paging);
    } else {
      toast.error(_categoriesResponse.response?.message);
    }
  }, [_categoriesResponse]);

  useEffect(() => {
    setLoading(_categoryUpdateResponse.isLoading);
    if (
      _categoryUpdateResponse.response &&
      _categoryUpdateResponse.response.status === 200
    ) {
      setCategory({});
      dispatch(
        getShopCategoriesAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_categoryUpdateResponse.response?.message);
    }
  }, [_categoryUpdateResponse]);

  useEffect(() => {
    setLoading(_categoryAddResponse.isLoading);
    if (
      _categoryAddResponse.response &&
      _categoryAddResponse.response.status === 200
    ) {
      setCategory({});
      dispatch(
        getShopCategoriesAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_categoryAddResponse.response?.message);
    }
  }, [_categoryAddResponse]);

  useEffect(() => {
    setLoading(_categoryDeleteResponse.isLoading);
    if (
      _categoryDeleteResponse.response &&
      _categoryDeleteResponse.response.status === 200
    ) {
      setCategory({});
      dispatch(
        getShopCategoriesAsync({ page: page.page, page_size: page.page_size })
      );
    } else {
      toast.error(_categoryDeleteResponse.response?.message);
    }
  }, [_categoryDeleteResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "updated_at"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getShopCategoriesAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getShopCategoriesAsync({
          page: page.page,
          page_size: page.page_size,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getShopCategoriesAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
      })
    );
  };

  const onDelete = () => {
    setShowDeleteModel(false);
    const data = {
      id: categories[index].id,
    };
    dispatch(deleteShopCategoryAsync(data));
  };

  const onAddCategory = () => {
    setShowAddModel(false);
    dispatch(addShopCategoryAsync(category));
  };

  const onEditCategory = () => {
    setShowEditModel(false);
    delete category.created_at;
    dispatch(updateShopCategoryAsync(category));
  };

  const handleActiveChange = (index) => {
    setIndex(index);
    dispatch(
      updateShopCategoryAsync({
        id: categories[index].id,
        active: !categories[index].active,
      })
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Categories
              <p className="text-muted">All Categories</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <span
                    onClick={handleAddModelShow}
                    to="#ecommerce-add"
                    className="btn btn-primary btn-rounded box-shadow btn-icon"
                  >
                    <i className="fa fa-plus" /> Add Category
                  </span>
                </Col>
                <Col md="auto">
                  <div className="mb-2">
                    <InputGroup>
                      <Form.Control
                        value={query}
                        onChange={onQueryChange}
                        type="text"
                        placeholder="Search"
                      />
                      <div className="input-group-btn">
                        <Button variant="primary" onClick={onSearch}>
                          <i className="fa fa-search" />
                        </Button>
                      </div>
                    </InputGroup>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {categories.map((item, i) => {
                      return (
                        <tr className="text-center">
                          <td>{item.id}</td>
                          <td>{item.name}</td>

                          <td>{item.created_at}</td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`switch_${i}`}
                                checked={item.active}
                                readOnly
                                onChange={() => {
                                  handleActiveChange(i);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`switch_${i}`}
                              />
                            </div>
                          </td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleEditModelShow(i)}
                            >
                              <i className="fa fa-edit" />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteModelShow(i)}
                            >
                              <i className="fa fa-trash" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal size="lg" show={showEditModel} onHide={handleEditModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={category.name}
                  onChange={(e) => {
                    setCategory({ ...category, name: e.target.value });
                  }}
                  type="text"
                  placeholder="Title"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!category.name || category.name == "") {
                toast.error("Please enter title");
              } else {
                onEditCategory();
              }
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showAddModel} onHide={handleAddModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  value={category.name}
                  onChange={(e) => {
                    setCategory({ ...category, name: e.target.value });
                  }}
                  type="text"
                  placeholder="Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAddModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (!category.name || category.name == "") {
                toast.error("Please enter title");
              } else {
                onAddCategory();
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModel} onHide={handleDeleteModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete.?</p>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              onDelete();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(ShopCategories);
