import axios from "axios";
import { getUser } from "../../utils/utils";
import { API_ENDPOINTS } from "./api.constants";
import * as CryptoJS from "crypto-js";

export const decryptData = (data) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      decodeURIComponent(data),
      API_ENDPOINTS.ENCRYPTION_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    return null;
  }
};

export const getAPICall = async (endpoint, params, isBlob = false) => {
  var user = getUser();
  try {
    const response = await axios.get(endpoint, {
      params: params,
      responseType: isBlob ? "blob" : "json",
      headers: {
        "api-key": API_ENDPOINTS.APIKEY,
        Authorization: user ? `Bearer ${user.token}` : "",
      },
    });

    if (response.data && typeof response.data === "string") {
      try {
        response.data = decryptData(response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }

    return { data: response.data };
  } catch (e) {
    if ( e.response.data && typeof  e.response.data === "string") {
      try {
        e.response.data = decryptData( e.response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }
    return { data: e.response.data };
  }
};

export const deleteAPICall = async (endpoint, params) => {
  var user = getUser();
  try {
    const response = await axios.delete(endpoint, {
      params: params,
      headers: {
        "api-key": API_ENDPOINTS.APIKEY,
        Authorization: user ? `Bearer ${user.token}` : "",
      },
    });

    if (response.data && typeof response.data === "string") {
      try {
        response.data = decryptData(response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }

    return { data: response.data };
  } catch (e) {
    if ( e.response.data && typeof  e.response.data === "string") {
      try {
        e.response.data = decryptData( e.response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }
    return { data: e.response.data };
  }
};

export const postAPICall = async (endpoint, params, formdata = false) => {
  var user = getUser();
  var body = new FormData();
  if (formdata) {
    Object.keys(params).forEach((key) => {
      body.append(key, params[key]);
    });
  }
  try {
    const response = await axios.post(
      endpoint,
      formdata ? body : JSON.stringify(params),
      {
        headers: {
          "api-key": API_ENDPOINTS.APIKEY,
          "Content-Type": formdata ? "" : "application/json",
          Authorization: user ? `Bearer ${user.token}` : "",
        },
      }
    );

    if (response.data && typeof response.data === "string") {
      try {
        response.data = decryptData(response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }
    return { data: response.data };
  } catch (e) {
    if ( e.response.data && typeof  e.response.data === "string") {
      try {
        e.response.data = decryptData( e.response.data);
      } catch (error) {
        console.error("Failed to decrypt response data:", error);
      }
    }
    return { data: e.response.data };
  }
};
