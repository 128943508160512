import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store.js";

// auth
import Login from "./pages/auth/";
import Dashboard from "./pages/dashboard/";
import Users from "./pages/users/";
import SubscriptionRequests from "./pages/subscription-requests/";
import SubscriptionPlans from "./pages/subscription-plans/";
import FAQCategories from "./pages/faq-categories/";
import FAQ from "./pages/faq/";
import Questions from "./pages/questions/index.jsx";
import Sessions from "./pages/sessions/index.jsx";
import PrivacyPolicy from "./pages/privacy-policy/index.jsx";
import TermsConditions from "./pages/terms-conditions/index.jsx";
import Blogs from "./pages/blogs/index.jsx";
import ShopCategories from "./pages/shop-categories/index.jsx";
import Products from "./pages/shop-products/index.jsx";
import PurchaseRequests from "./pages/purchase-requests/index.jsx";


// //apps

import Page404 from "./pages/page-error/page-404";

import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "./utils/auth_context";
import PrivateRoute from "./utils/private_route";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter basename={"/"}>
          <Routes>
            <Route exact path="/" element={<Login />} />

            <Route element={<PrivateRoute />}>
              <Route
                exact
                path="/"
                render={() => <Navigate to="/dashboard" />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/requests" element={<SubscriptionRequests />} />
              <Route path="/plans" element={<SubscriptionPlans />} />
              <Route path="/faq-categories" element={<FAQCategories />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/sessions" element={<Sessions />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/shop-categories" element={<ShopCategories />} />
              <Route path="/products" element={<Products />} />
              <Route path="/purchase-requests" element={<PurchaseRequests />} />
             
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Provider>

    <ToastContainer
      position="bottom-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnHover={false}
    />
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
