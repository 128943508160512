import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  InputGroup,
  Form,
  Image,
  Tabs,
  Tab,
} from "react-bootstrap";
import BackendLayout from "../../layouts/backend-layout";

import { useSelector, useDispatch } from "react-redux";
import {
  getRequestsAsync,
  updateRequestAsync,
  requestsResponse,
  updateRequestResponse,
  resetAsync,
} from "../../api/slices/shop.slice";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../api/client/api.constants";

function PurchaseRequests(props) {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const _requestsResponse = useSelector(requestsResponse);
  const _updateRequestResponse = useSelector(updateRequestResponse);

  const [showImageModel, setShowImageModel] = React.useState(false);
  const handleImageModelClose = () => setShowImageModel(false);
  const handleImageModelShow = (request) => {
    setRequest(request);
    setShowImageModel(true);
  };

  const [showRequestUpdateModel, setShowRequestUpdateModel] =
    React.useState(false);
  const handleRequestUpdateModelClose = () => {
    setShowRequestUpdateModel(false);
    setReason(undefined);
  };
  const handleRequestUpdateModelShow = (request, status) => {
    setRequest(request);
    setStatus(status);
    setShowRequestUpdateModel(true);
  };

  const [page, setPage] = useState({
    page: 1,
    page_size: 15,
  });
  const [requests, setRequests] = useState([]);
  const [filter, setFilter] = useState("PENDING");
  const [order_by, setOrderBy] = useState("DESC");
  const [reason, setReason] = useState();
  const [status, setStatus] = useState();
  const [query, setQuery] = useState();
  const [request, setRequest] = useState();
  const [paging, setPaging] = useState([]);

  const columns = [
    { key: "id", value: "ID" },
    { key: "first_name", value: "First Name" },
    { key: "middle_name", value: "Middle Name" },
    { key: "last_name", value: "Last Name" },
    { key: "email", value: "Email" },
    { key: "name", value: "Product" },
    { key: "price", value: "Price" },
    { key: "request_status", value: "Status" },
    { key: "created_at", value: "Created At" },
    { key: "action", value: "Action" },
  ];

  useEffect(() => {
    dispatch(
      getRequestsAsync({ page: page.page, page_size: page.page_size, filter })
    );
  }, [page]);

  useEffect(() => {
    setLoading(_requestsResponse.isLoading);
    if (
      _requestsResponse.response &&
      _requestsResponse.response.status === 200
    ) {
      window.scrollTo(0, 0);
      setRequests(_requestsResponse.response.data.requests);
      setPaging(_requestsResponse.response.data.paging);
    } else {
      toast.error(_requestsResponse.response?.message);
    }
  }, [_requestsResponse]);

  useEffect(() => {
    setLoading(_updateRequestResponse.isLoading);
    if (
      _updateRequestResponse.response &&
      _updateRequestResponse.response.status === 200
    ) {
      toast.success(_updateRequestResponse.response?.message);
      dispatch(
        getRequestsAsync({ page: page.page, page_size: page.page_size, filter })
      );
    } else {
      toast.error(_updateRequestResponse.response?.message);
    }
  }, [_updateRequestResponse]);

  const onPageChange = (i) => {
    setPage({ ...page, page: i });
  };

  const onOrderClick = (key) => {
    const ignoreKey = ["action", "created_at", "current_place"];
    if (ignoreKey.includes(key)) {
      return;
    }
    const order = order_by == "DESC" ? "ASC" : "DESC";
    setOrderBy(order);
    dispatch(
      getRequestsAsync({
        page: page.page,
        page_size: page.page_size,
        order_by: key,
        order,
        filter,
      })
    );
  };

  const onQueryChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value == "") {
      dispatch(
        getRequestsAsync({
          page: page.page,
          page_size: page.page_size,
          filter,
        })
      );
    }
  };

  const onSearch = () => {
    dispatch(
      getRequestsAsync({
        page: page.page,
        page_size: page.page_size,
        search: query,
        filter,
      })
    );
  };

  const onTabChange = (status) => {
    setFilter(status);
    setPage({
      page: 1,
      page_size: 15,
    });

  };

  const onRequestUpdate = () => {
    setShowRequestUpdateModel(false);
    const data = {
      id: request.id,
      request_status: status,
    };
    if (status === "REJECTED") {
      data.reason = reason;
    }
    dispatch(updateRequestAsync(data));
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Requests
              <p className="text-muted">All Subscription Request</p>
            </Card.Header>
            <Card.Body>
              <Row className="ml-1">
                <Col>
                  <Tabs
                    onSelect={onTabChange}
                    defaultActiveKey="PENDING"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="PENDING" title="Pending"></Tab>
                    <Tab eventKey="APPROVED" title="Approved"></Tab>
                    <Tab eventKey="REJECTED" title="Rejected"></Tab>
                  </Tabs>
                </Col>
                <Col md="auto">
                  <InputGroup>
                    <Form.Control
                      value={query}
                      onChange={onQueryChange}
                      type="text"
                      placeholder="Search"
                    />
                    <div className="input-group-btn">
                      <Button variant="primary" onClick={onSearch}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </InputGroup>
                </Col>
              </Row>
              <div className="table-responsive">
                <Table
                  id="datatable1"
                  striped="columns"
                  className="table table-striped table-hover mt-4"
                >
                  <thead>
                    <tr>
                      {columns.map((item) => {
                        return (
                          <th
                            role="button"
                            nowrap="nowrap"
                            className="text-center"
                            onClick={() => onOrderClick(item.key)}
                          >
                            <Row className="justify-content-md-center ml-2 mr-2">
                              <strong>{item.value}</strong>
                            </Row>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((item) => {
                      return (
                        <tr>
                          <td>{item.id}</td>
                          <td>{item.user.first_name}</td>
                          <td>{item.user.middle_name || "N/A"}</td>
                          <td>{item.user.last_name}</td>
                          <td nowrap="nowrap">{item.user.email}</td>
                          <td nowrap="nowrap">{item.product.name}</td>
                          <td>{item.product.price}</td>
                          <td>{item.request_status}</td>
                          <td nowrap="nowrap">{item.created_at}</td>
                          <td nowrap="nowrap" className="text-center">
                            <Button
                              className="btn btn-sm btn-default"
                              onClick={() => handleImageModelShow(item)}
                            >
                              <i className="fa fa-eye" />
                            </Button>{" "}
                            <Button
                              hidden={filter == "APPROVED"}
                              variant="success"
                              size="sm"
                              onClick={() =>
                                handleRequestUpdateModelShow(item, "APPROVED")
                              }
                            >
                              <i className="fa fa-check" />
                            </Button>{" "}
                            <Button
                              hidden={filter == "REJECTED"}
                              variant="danger"
                              size="sm"
                              onClick={() =>
                                handleRequestUpdateModelShow(item, "REJECTED")
                              }
                            >
                              <i className="fa fa-times" />
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Pagination>
                {Array.from({ length: paging.total_pages }).map((item, i) => {
                  return (
                    <Pagination.Item
                      onClick={() => onPageChange(i + 1)}
                      key={i}
                      active={i + 1 === paging.currentPage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showImageModel} onHide={handleImageModelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Image
              fluid
              alt="#"
              src={`${API_ENDPOINTS.IMAGE}${request?.media}`}
            />
          </Col>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRequestUpdateModel}
        onHide={handleRequestUpdateModelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{status?.toLowerCase()} Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to {status?.toLowerCase()} request.?</p>
          <form hidden={status == "APPROVED"}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    type="text"
                    placeholder="Reason"
                  />
                </Form.Group>
              </Col>
            </Row>
          </form>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRequestUpdateModelClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (status === "REJECTED" && (!reason || reason == "")) {
                toast.error("Please enter rejection reason");
              } else {
                onRequestUpdate();
              }
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
export default BackendLayout(PurchaseRequests);
